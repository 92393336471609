import { observer } from "mobx-react-lite";
import { useStore } from "../context/useSession";
import classes from './title.module.css';
import clsx from "clsx";

export const SubtitleElement = observer(() => {
    const { sessionStore } = useStore();
    return <div 
            className={clsx("h-full w-full text-[40px] leading-[2.5rem] text-center text-white opacity-85", classes.titleFont)}
        >
            {sessionStore.subtitle}
        </div>
})