import { RootStore } from "@/stores/RootStore";
import { createContext, useContext } from "react";

export const RootContext = createContext<RootStore | undefined>(undefined);
export const RootProvider = RootContext.Provider;

export const useStore = () => {
    const session = useContext(RootContext);
    if (!session) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return session;
}

