import { observer } from "mobx-react-lite";
import { useStore } from "../context/useSession";
import { useCallback } from "react";
import { MAP_COLOR_NAMES, PATH_COLOR_NAMES, getMapColor, getPathColor } from "@/utils/PathColors";
import { ColorInput } from "./components/ColorInput";
import { NumberInput } from "./components/NumberInput";
import { TextInput } from "./components/TextInput";

export const DesignPanel = observer(() => {
    return (
        <div className="grid gap-2">
            <TitleInput />
            <SubtitleInput />
            <MapColorInput/>
            <PathColorInput />
            <ThicknessInput />
        </div>
    )
})

const TitleInput = observer(() => {
    const { sessionStore } = useStore();

    const onTitleChanged = useCallback((value: string) => {
        sessionStore.title = value;
    }, [sessionStore]);

    return (
        <TextInput label="Title" id="title" placeholder="Title" value={sessionStore.title} onInput={onTitleChanged} />
    )
});

const SubtitleInput = observer(() => {
    const { sessionStore } = useStore();

    const onSubtitleChanged = useCallback((value: string) => {
        sessionStore.subtitle = value;
    } , [sessionStore]);

    return (
        <TextInput label="Subtitle" id="subtitle" placeholder="Subtitle" value={sessionStore.subtitle} onInput={onSubtitleChanged} />
    )
})

const PathColorInput = observer(() => {
    const { sessionStore } = useStore();

    const onClick = (color: string) => {
        const selectedColor = PATH_COLOR_NAMES.find(name => getPathColor(name).hex === color);
        if (selectedColor) {
            sessionStore.pathColor = selectedColor;
        }
    }

    return (
        <ColorInput 
            label="Line Color"
            colors={PATH_COLOR_NAMES.map(color => getPathColor(color).hex)}
            selectedColor={getPathColor(sessionStore.pathColor).hex}
            onChange={onClick}
        />
    );
});

const MapColorInput = observer(() => {
    const { sessionStore } = useStore();

    const onClick = (color: string) => {
        const selectedColor = MAP_COLOR_NAMES.find(name => getMapColor(name).hex === color);
        if (selectedColor) {
            sessionStore.mapColor = selectedColor;
        }
    }

    return (
        <ColorInput
            label="Map Color"
            colors={MAP_COLOR_NAMES.map(color => getMapColor(color).hex)}
            selectedColor={getMapColor(sessionStore.mapColor).hex}
            onChange={onClick}
        />
    );
});

const ThicknessInput = observer(() => {
    const { sessionStore } = useStore();

    return (
        <NumberInput
            label="Line Thickness"
            min={1}
            max={10}
            value={sessionStore.pathThickness}
            onChange={(value) => {
                sessionStore.pathThickness = value;
            }}
        />
    );
})