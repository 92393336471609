import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import { bbox } from "@turf/turf";
import { MapRef } from "react-map-gl";

export class MapStore implements Disposable {
    _map: MapRef | null = null;

    constructor(private readonly rootStore: RootStore) {
        makeAutoObservable(this);
    }

    [Symbol.dispose]() {
        this._map = null;
    }

    get map() {
        return this._map;
    }

    set map(map: MapRef | null) {
        this._map = map;
    }

    /** returns the northeaster and southwester corner of all the geojson files */
    get bbox(): [[number, number], [number, number]] | undefined {
        const features = this.rootStore.activityStore.visibleMaps.flatMap(([, data]) => data.features);
        console.log(features)
        if (features.length === 0) {
            return undefined;
        }

        const featureBbox = bbox({
            type: 'FeatureCollection',
            features: features,
        });

        return [
            [featureBbox[0], featureBbox[1]],
            [featureBbox[2], featureBbox[3]],
        ]
    }

    get viewState() {
        return {
            longitude: 151.2093,
            latitude: -33.8688,
            zoom: 13,
        }
    }
}