import { PrintSize } from "@/utils/PrintSize";
import { calculateZoomToFit } from "@/utils/ZoomUtils";
import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import { MapColorName, PathColorName, getMapColor, getMapTheme, getPathColor } from "@/utils/PathColors";

export class SessionStore implements Disposable {
    private _printSize: PrintSize = 'A4';
    private _zoom = 0.75;
    private _isFitToScreen = true;
    private _editorContainer: HTMLDivElement | undefined = undefined;
    private _editorContainerSize: { width: number, height: number } = { width: 0, height: 0 };
    private _title: string = 'My Title';
    private _subtitle: string = 'My Subtitle';
    private _pathColor: PathColorName = 'ORANGE';
    private _pathThickness: number = 3;
    private _mapColor: MapColorName = 'DARK';

    constructor(private readonly rootStore: RootStore) {
        makeAutoObservable(this)
    }

    [Symbol.dispose]() {
        this._editorContainer = undefined;
    }

    get printSize() {
        return this._printSize;
    }

    set printSize(size: PrintSize) {
        this._printSize = size;
    }

    get zoom() {
        return this._zoom;
    }

    set zoom(zoom: number) {
        if (zoom < 0.1) {
            this._zoom = 0.1;
            return;
        }

        if (zoom > 2) {
            this._zoom = 2;
            return;
        }

        this._zoom = zoom;
    }

    get isFitToScreen() {
        return this._isFitToScreen;
    }

    set isFitToScreen(value: boolean) {
        this._isFitToScreen = value;
    }

    get editorContainer() {
        return this._editorContainer;
    }

    set editorContainer(container: HTMLDivElement | undefined) {
        this._editorContainer = container;
    }

    get title() {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    get subtitle() {
        return this._subtitle;
    }

    set subtitle(subtitle: string) {
        this._subtitle = subtitle;
    }

    get pathColor() {
        return this._pathColor;
    }

    set pathColor(name: PathColorName) {
        this._pathColor = name;
    }

    get pathColorValue() {
        return getPathColor(this._pathColor).hex;
    }

    get pathThickness() {
        return this._pathThickness;
    }

    set pathThickness(thickness: number) {
        this._pathThickness = thickness;
    }

    get mapColor() {
        return this._mapColor;
    }

    set mapColor(name: MapColorName) {
        this._mapColor = name;
    }

    get mapColorValue() {
        return getMapColor(this._mapColor).hex;
    }

    get mapTheme() {
        return getMapTheme(this._mapColor);
    }

    set editorContainerSize(size: { width: number, height: number }) {
        this._editorContainerSize = size;
    }

    get editorContainerSize() {
        return this._editorContainerSize;
    }

    get bottomPanelPosition() {
        // The editor container contains the editor and the bottom panel
        // The editor appears in the center of the container
        // The bottom panel appears below the editor

        const editorSize = this.rootStore.editorStore.dimensions;
        const containerSize = this._editorContainerSize;

        const editorY = (containerSize.height - editorSize.height) / 2;
        const editorBottom = editorY + editorSize.height;

        let bottomPanelY = editorBottom + 20;

        // If the bottom panel is too close to the bottom of the container, move it up
        if (bottomPanelY + 40 > containerSize.height) {
            bottomPanelY = containerSize.height - 40;
        }

        return bottomPanelY;
    }

    zoomToFit() {
        if (!this._editorContainer) {
            return;
        }

        const { width: containerWidth, height: containerHeight } = this._editorContainer.getBoundingClientRect();
        this._editorContainerSize = { width: containerWidth, height: containerHeight };
        const { width, height } = this.rootStore.editorStore.unzoomedDimensions;

        const ratio = this.rootStore.deviceStore.deviceType === 'desktop' ? 0.8 : 0.9;
        this.zoom = parseFloat((calculateZoomToFit(width, height, containerWidth, containerHeight) * ratio).toFixed(3))
    }
}

