import { useRef, useEffect, useCallback } from 'react';
import Map, { Layer, MapRef, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useStore } from '../context/useSession';
import { observer } from 'mobx-react-lite';
import { getMapTheme } from '@/utils/PathColors';

function useHookWithRefCallback<T>(callback: (instance: T | null) => void) {
    const ref = useRef<T | null>(null);

    const setRef = useCallback((node: T) => {
        if (ref.current) {
            // Make sure to cleanup any events/references added to the last instance
        }

        if (node) {
            callback(node);
        }

        ref.current = node;
    }, [callback])

    return {ref, setRef};
}

export const Mapbox = observer(() => {
    const { sessionStore, mapStore } = useStore();
    const {setRef, ref} = useHookWithRefCallback<MapRef>((map) => {
        mapStore.map = map;
        map?.on('wheel', (e) => {
            if (e.originalEvent.ctrlKey) {
                return;
            }
        })
        map?.on('touchmove', (e) => {
            e.preventDefault();
        })
    });

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const map = ref.current.getMap();
        if (!mapStore.bbox) {
            return;
        }
        map.fitBounds(mapStore.bbox, { padding: {
            top: 50,
            bottom: 200,
            left: 50,
            right: 50
        }})
    }, [mapStore.bbox, ref])

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const map = ref.current.getMap();
        map.setStyle(sessionStore.mapTheme);
    }, [ref, sessionStore.mapTheme])

    return <Map
      mapboxAccessToken={import.meta.env.VITE_MAPBOX_SECRET}
      ref={setRef}
      initialViewState={mapStore.viewState}
      style={{width: "100%", height: "100%"}}
      reuseMaps={true}
      mapStyle={getMapTheme('DARK')}
    >
        <Layers/>
    </Map>
})

const Layers = observer(() => {
    const { sessionStore, mapDataStore } = useStore();
    return <>
        {
            mapDataStore.geojson.map((map) => {
                return <Source type="geojson" data={map.data} key={map.id}>
                    <Layer 
                        id={map.id}
                        type="line"
                        paint={{
                            'line-color': sessionStore.pathColorValue,
                            'line-width': sessionStore.pathThickness,
                        }}
                    />
                </Source>
            })
        }
    </>
})