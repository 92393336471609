import { observer } from "mobx-react-lite";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { useStore } from "../context/useSession";
import { Button } from "../ui/button";

export const ZoomControl = observer(() => {
    const { sessionStore } = useStore();

    const handleFitToScreen = () => {
        sessionStore.isFitToScreen = true;
        sessionStore.zoomToFit();
    }

    const handleZoomTo = (value: number) => {
        sessionStore.isFitToScreen = false;
        sessionStore.zoom = value;
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild><Button variant="ghost">{Math.trunc(sessionStore.zoom*100)}%</Button></DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuItem onClick={() => handleFitToScreen()}>Fit to Screen</DropdownMenuItem>
                <DropdownMenuSeparator/>
                <DropdownMenuItem onClick={() => handleZoomTo(0.1)}>10%</DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleZoomTo(0.25)}>25%</DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleZoomTo(0.5)}>50%</DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleZoomTo(1)}>100%</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
})
