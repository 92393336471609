import { observer } from "mobx-react-lite";
import { useStore } from "../context/useSession";
import classes from './title.module.css';
import clsx from "clsx";

export const TitleElement = observer(() => {
    const { sessionStore } = useStore();
    return <div 
            className={clsx("h-full w-full text-[60px] leading-[3rem] text-center text-white opacity-90", classes.titleFont)}
        >
            {sessionStore.title}
        </div>
})