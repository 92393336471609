import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { StravaOAuth } from './lib/strava.ts'
import * as Sentry from "@sentry/react";
import { RootStore } from './stores/RootStore.ts'
import { RootProvider } from './components/context/useSession.ts'

Sentry.init({
  dsn: "https://8b1e30a29b73ac92cd0bc9a6de448801@o1102313.ingest.us.sentry.io/4507373724303360",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/pathprint\.app/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Check the query parameters. 
// If code and scope are present, it means the user has been redirected from Strava OAuth.
(async () => {
  if (window.location.search.includes('code') && window.location.search.includes('scope')) {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const scope = params.get('scope');

    try {
      if (code && scope) {
        const token = await StravaOAuth.getToken(code, scope);
        StravaOAuth.saveToken(token);
        // Redirect to the root URL to remove the query parameters
        window.location.href = '/';
      }
    } catch (error) {
      console.error(error);
    }
  }

  const session = new RootStore();

  window.addEventListener('beforeunload', () => {
    session[Symbol.dispose]();
  });

  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <RootProvider value={session}>
        <App />
      </RootProvider>
    </React.StrictMode>,
  )
})();
