import { Eye, EyeOff, Loader2, Upload } from "lucide-react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { useCallback, useRef, useState } from "react";
import { toast } from "sonner";
import { useStore } from "../context/useSession";
import { observer } from "mobx-react-lite";
import { StravaOAuth } from "@/lib/strava";
import { ScrollArea } from "../ui/scroll-area";
import { PoweredByStravaLogo } from "./PoweredByStrava";
import { PPActivity } from "@/stores/ActivityStore";

export const ActivityPanel = observer(() => {
    const { deviceStore } = useStore();
    return (
        <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
                <ConnectWithStravaButton />
                <FileUploadButton />
            </div>
            <ScrollArea className="md:max-h-full md:min-h-[150px] min-h-none max-h-[40vh]">
                <ActivityList />
            </ScrollArea>
            {
                deviceStore.deviceType === 'mobile' &&
                <div className="-m-2">
                    <PoweredByStravaLogo />
                </div>
            }
        </div>
    )
})

const ConnectWithStravaButton = observer(() => {
    const { stravaStore } = useStore();

    const handleClick = () => {
        StravaOAuth.authorize();
    }

    return (
        <Button className="bg-orange-500" onClick={handleClick} disabled={stravaStore.isAuthenticated}>{stravaStore.isAuthenticated ? "Logged in to Strava" : "Connect with Strava"}</Button>
    )
});

const ActivityList = observer(() => {
    const { activityStore } = useStore();

    return (
        <div className="flex flex-col">
            {activityStore.activities.map((activity) => (
                <Entry key={activity.id} activity={activity} />
            ))}
        </div>
    )
})

const Spinner = () => {
    return <Loader2 className="animate-spin h-4 w-4" />
}

const Entry = observer(({ activity }: { activity: PPActivity }) => {
    const { activityStore } = useStore();
    const isAdded = activityStore.isActivityVisible(activity.id);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = useCallback(async () => {
        if (activityStore.isActivityVisible(activity.id)) {
            activityStore.setActivityVisible(activity.id, false);
            return;
        }
        setIsLoading(true);
        await activityStore.getActivityData(activity);
        activityStore.setActivityVisible(activity.id, true);
        setIsLoading(false);
    }, [activity, activityStore])

    return (
        <div className="group/item hover:bg-neutral-100 hover:cursor-pointer flex items-center gap-2 rounded-md px-3 py-3" onClick={handleClick}>
            <div className="flex flex-col">
                { isLoading ? <Spinner/> :
                    isAdded
                    ? <Eye className={`h-4 w-4`} />
                    : <EyeOff className={`h-4 w-4`} />
                }
            </div>
            <div className="flex flex-col flex-grow select-none">
                <span className="font-medium">{activity.name}</span>
                <div className="text-sm uppercase gap-2 grid grid-cols-4">
                <span>{activity.distanceMetres ? (activity.distanceMetres/1000).toFixed(2) + 'km' : '-' }</span>
                <span>{
                    activity.startDate.toLocaleDateString(undefined, {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit'
                    })
                    }
                </span>
                </div>
            </div>
        </div>
    )
});

function FileUploadButton() {
    const ref = useRef<HTMLInputElement>(null);
    const { fileStore } = useStore();

    const handleClick = () => {
        ref.current?.click();
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files == null || files.length === 0) {
            return;
        }

        if (files.length > 1) {
            const results = await fileStore.addFiles(files);

            if (results.success.length > 0 && results.failed.length === 0) {
                toast.success(`Uploaded ${results.success.length} files`);
            } else if (results.success.length > 0 && results.failed.length > 0) {
                toast.error(`Uploaded ${results.success.length} files, failed to upload ${results.failed.length} files`);
            } else {
                toast.error(`Failed to upload ${results.failed.length} files`);
            }
        } else {
            try {
                await fileStore.addFile(files[0]);
                toast.success(`Uploaded ${files[0].name}`);
            } catch (e) {
                toast.error(`Failed to upload ${files[0].name}`);
            }
        }


        e.target.value = '';
        return;
    }

    return (
        <>
            <Button onClick={handleClick}><Upload className="mr-2 h-4 w-4" />Upload GPX</Button>
            <Input onInput={handleFileChange} ref={ref} type="file" accept=".gpx" className="hidden" multiple />
        </>
    )
}