import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";

export class MapDataStore implements Disposable {
    constructor(private readonly rootStore: RootStore) {
        makeAutoObservable(this)
    }

    [Symbol.dispose]() {
    }

    get geojson() {
        return this.rootStore.activityStore.visibleMaps.map(([id, data]) => ({ id, data }));
    }
}