import { makeAutoObservable } from "mobx";
import { getPrintSizeDimensionsPixels } from "@/utils/PrintSize";
import { RootStore } from "./RootStore";
import { v4 as uuidv4 } from 'uuid';

export class EditorStore implements Disposable {
    items: Map<string, EditorItemBase> = new Map();

    constructor(
        private readonly rootStore: RootStore
    ) {
        makeAutoObservable(this);
    }

    [Symbol.dispose]() {
        this.items.clear();
    }

    addItem(item: Omit<EditorItemBase, 'id'>) {
        const id = uuidv4();
        this.items.set(id, {id, ...item});
        return id;
    }
    
    removeItem(id: string) {
        this.items.delete(id);
    }

    get render() {
        return Array.from(this.items.values()).map(item => {
            return {
                ...item,
            }
        })
    }

    get zoom() {
        return this.rootStore.sessionStore.zoom;
    }

    get unzoomedDimensions() {
        const { width, height } = getPrintSizeDimensionsPixels(this.rootStore.sessionStore.printSize, 100);

        return {
            width,
            height,
        }
    }

    get dimensions() {
        const { width, height } = this.unzoomedDimensions;
        return {
            width: width * this.zoom,
            height: height * this.zoom,
        }
    }
}

export type EditorItemBase = {
    /**
     * The unique identifier of the item
     */
    id: string;
    /**
     * The horizontal position of the item
     */
    x: number;
    /**
     * The vertical position of the item
     */
    y: number;
    /**
     * The width of the item
     */
    width: number;
    /**
     * The height of the item
     */
    height: number;
    element: React.ReactNode;
}

