import { observer } from "mobx-react-lite";
import { Label } from "../ui/label";
import { PrintSizeCombobox } from "./PrintSizeCombobox";
import { useStore } from "../context/useSession";

export const PrintSizePanel = observer(() => {
    const { deviceStore, sessionStore, mapStore } = useStore();
    const { printSize } = sessionStore;
    return (
        <div className="grid gap-2">
            <div className="grid w-full items-center gap-1.5">
                <Label>Print Size</Label>
                <PrintSizeCombobox
                    isDesktop={deviceStore.deviceType === 'desktop'}
                    printSize={printSize}
                    onPrintSizeChanged={(value) => {
                        sessionStore.printSize = value;
                        sessionStore.isFitToScreen = true;
                        sessionStore.zoomToFit();
                        setTimeout(() => {
                            if (mapStore.bbox) {
                                mapStore.map?.fitBounds(mapStore.bbox, {
                                    padding: { top: 50, bottom: 200, left: 50, right: 50 }
                                })
                            }
                        }, 0);
                    }} />
            </div>
        </div>
    )
})