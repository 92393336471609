import { observer } from "mobx-react-lite";
import { Button } from "../ui/button";
import { useStore } from "../context/useSession";

export const CenterMapButton = observer(() => {
    const { sessionStore, mapStore } = useStore();

    return (
        <div className="absolute flex gap-4" style={{ top: sessionStore.bottomPanelPosition }}>
            <Button className="rounded-full shadow-sm" size="sm" 
                onClick={() => {
                    const { bbox } = mapStore;
                    if (bbox) mapStore.map?.fitBounds(bbox)
                }}>
                Center Map
            </Button>
            <Button className="rounded-full shadow-sm" size="sm" 
                onClick={() => sessionStore.zoomToFit()}>
                Fit to Content
            </Button>
        </div>
    )

});