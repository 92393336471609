import { Label } from "@/components/ui/label";
import clsx from "clsx";

type ColorPickerProps = {
    /**
     * The label of the color picker
     */
    label: string;
    /**
     * A list of available colors' hex values
     */
    colors: string[];
    /**
     * The hex value of the currently selected color
     */
    selectedColor: string;
    /**
     * A function that is called when the selected color changes
     */
    onChange: (color: string) => void;
}

export const ColorInput = ({ label, colors, selectedColor, onChange }: ColorPickerProps) => {
    return (
        <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="subtitle">{label}</Label>
            <div className="flex gap-1.5">
                {
                    colors.map((color) =>
                        <div key={color} className="cursor-pointer" onClick={() => onChange(color)}>
                            <div className={clsx("w-10 h-10 rounded-full", color === selectedColor && `border-2 shadow-[0_0_0_2px_rgba(255,255,255,1)_inset]`)} style={{ backgroundColor: color, borderColor: color }} />
                        </div>
                    )
                }
            </div>
        </div>
    );
};