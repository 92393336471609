import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

type NumberInputProps = {
    /**
     * The label of the input
     */
    label: string;
    /**
     * The minimum value of the input
     */
    min: number;
    /**
     * The maximum value of the input
     */
    max: number;
    /**
     * The value of the input
     */
    value: number;
    /**
     * A function that is called when the value changes 
     */
    onChange: (value: number) => void;
}

export const NumberInput = ({ label, min, max, value, onChange }: NumberInputProps) => {
    const _onChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        if (value >= min && value <= max) {
            onChange(value);
        }
    }

    return (
        <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="subtitle">{label}</Label>
            <Input type="number" min={min} max={max} value={value} onInput={_onChange} />
        </div>
    );
};
