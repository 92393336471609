import { ActivityStore } from "./ActivityStore";
import { DeviceStore } from "./DeviceStore";
import { EditorStore } from "./EditorStore";
import { FileStore } from "./FileStore";
import { MapDataStore } from "./MapDataStore";
import { MapStore } from "./MapStore";
import { SessionStore } from "./SessionStore";
import { StravaStore } from "./StravaStore";

export class RootStore implements Disposable {
    readonly fileStore: FileStore;
    readonly sessionStore: SessionStore;
    readonly editorStore: EditorStore;
    readonly mapStore: MapStore;
    readonly stravaStore: StravaStore;
    readonly deviceStore: DeviceStore;
    readonly activityStore: ActivityStore;
    readonly mapDataStore: MapDataStore;

    constructor() {
        this.fileStore = new FileStore(this);
        this.sessionStore = new SessionStore(this);
        this.editorStore = new EditorStore(this);
        this.mapStore = new MapStore(this);
        this.stravaStore = new StravaStore(this);
        this.deviceStore = new DeviceStore();
        this.activityStore = new ActivityStore(this);
        this.mapDataStore = new MapDataStore(this);
    }

    [Symbol.dispose]() {
        this.fileStore[Symbol.dispose]();
        this.sessionStore[Symbol.dispose]();
        this.editorStore[Symbol.dispose]();
        this.mapStore[Symbol.dispose]();
        this.stravaStore[Symbol.dispose]();
        this.deviceStore[Symbol.dispose]();
        this.activityStore[Symbol.dispose]();
        this.mapDataStore[Symbol.dispose]();
    }
}