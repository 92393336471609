import { ZoomControl } from "./ZoomControl";
import { observer } from "mobx-react-lite";
import { ShareButton } from "./ShareButton";
import { PrintButton } from "./PrintButton";

export const Navbar = observer(() => {
    return (
        <div className="min-h-[58px] p-2 border-b bg-white-200 flex justify-between">
            <div className="flex justify-center items-center">
                <span className="md:ml-4 ml-2 select-none font-heading font-bold tracking-tighter md:text-2xl text-xl text-neutral-900 opacity-95">PathPrint</span>
            </div>
            <div className="flex gap-1 justify-center items-center">
                <ZoomControl/>
                <ShareButton/>
                <PrintButton/>
            </div>
        </div>
    )
})
