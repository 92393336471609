import { makeAutoObservable, runInAction } from "mobx";
import { DOMParser } from '@xmldom/xmldom'
import { gpx } from "@tmcw/togeojson";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import { RootStore } from "./RootStore";
import { v4 as uuidv4 } from 'uuid';

export type GPXActivity = {
    id: string;
    name: string;
    startDate: Date;
};

export class FileStore implements Disposable {
    private files: Map<string, FeatureCollection<Geometry, GeoJsonProperties>> = new Map();
    private order: string[] = [];

    // @ts-expect-error: RootStore is not used in this file
    constructor(private readonly rootStore: RootStore) {
        makeAutoObservable(this);
    }

    [Symbol.dispose]() {
        this.files.clear();
    }

    getFile(id: string): FeatureCollection<Geometry, GeoJsonProperties> | undefined {
        return this.files.get(id);
    }

    get gpxActivities(): GPXActivity[] {
        return Array.from(this.files.entries()).map(([key, value]) => {
            const name = value.features[0].properties?.name || 'Unknown';
            const maybeTime = value.features[0].properties?.time;
            const date = maybeTime ? new Date(value.features[0].properties?.time) : new Date();

            return { id: key, name, startDate: date} as GPXActivity;
        })
    }

    removeFile(id: string) {
        this.files.delete(id);
        this.order = this.order.filter(orderId => orderId !== id);
    }

    /**
     * Process this into GeoJSON
     * @param file 
     */
    async addFile(file: File): Promise<string> {
        // Validate file type
        const id = uuidv4();
        const geojson = await this.gpxFileToGeoJSON(file)
        runInAction(() => {
            this.files.set(id, geojson);
            this.order.push(id);
        });
        return file.name;
    }

    async addFiles(files: FileList): Promise<{ success: string[], failed: string[] }> {
        const results = await Promise.allSettled(Array.from(files).map(file => this.addFile(file)));

        const success = [];
        const failed = [];

        for (const result of results) {
            if (result.status === 'fulfilled') {
                success.push(result.value);
            } else {
                failed.push(result.reason);
            }
        }

        return { success, failed };
    }

    private gpxFileToGeoJSON(gpxFile: File) {
        return new Promise<FeatureCollection<Geometry, GeoJsonProperties>>((resolve, reject) => {
            try {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const xmlStr = e.target?.result as string;
                    const dom = new DOMParser().parseFromString(xmlStr, "text/xml");

                    resolve(gpx(dom));
                }

                reader.readAsText(gpxFile);
            } catch (e) {
                reject(e);
            }
        });
    }


}