import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { StravaService } from "@/lib/strava";
import { FeatureCollection } from "geojson";
import polyline from "@mapbox/polyline";
import { featureCollection, lineString } from "@turf/turf";

export type StravaActivity = {
  id: string;
  name: string;
  distanceMetres: number;
  movingTimeSeconds: number;
  startDate: Date;
  averageSpeed: number;
  maxSpeed: number;
  averageHeartrate: number;
  maxHeartRate: number;
};

export class StravaStore implements Disposable {
  private _accessToken: string | null = null;
  private _refreshToken: string | null = null;
  private _expiresAt: number | null = null;

  private _activities = new Map<string, StravaActivity>();
  private _maps = new Map<string, FeatureCollection>();

  // @ts-expect-error: RootStore is not used in this file
  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
    if (this.checkSessionStorageForToken()) {
        this.getActivities();
    }
  }

    [Symbol.dispose]() {
        this._activities.clear();
        this._maps.clear();
    }

  /**
   * @returns {boolean} true if token was found in session storage
   */
  private checkSessionStorageForToken(): boolean {
    const accessToken = sessionStorage.getItem("strava_access_token");
    const refreshToken = sessionStorage.getItem("strava_refresh_token");
    const expiresAt = sessionStorage.getItem("strava_expires_at");

    if (accessToken && refreshToken && expiresAt) {
      this._accessToken = accessToken;
      this._refreshToken = refreshToken;
      this._expiresAt = parseInt(expiresAt);
    }

    if (this._accessToken && this._refreshToken && this._expiresAt) {
      console.log("Found Strava tokens in session storage");
    } else {
      console.log("Missing Strava tokens in session storage");
      return false;
    }

    // Check if expired
    if (this._expiresAt && this._expiresAt * 1000 < Date.now()) {
      console.log("Strava token has expired");
      this._accessToken = null;
      this._refreshToken = null;
      this._expiresAt = null;
      return false;
    }
    return true;
  }

  get activities() {
    return Array.from(this._activities.values());
  }

  get maps() {
    return Array.from(this._maps.values());
  }

  getMapsForActivity(activityId: string) {
    return this._maps.get(activityId);
  }

  get geojson() {
    // turn LineString into GeoJSON
    return Array.from(this._maps.entries()).map(([key, value]) => {
      return {
        id: key.toString(),
        data: value,
      };
    });
  }

  get accessToken() {
    return this._accessToken;
  }

  get isAuthenticated() {
    return !!this._accessToken;
  }

  async getActivity(activityId: string) {
    if (!this._accessToken) {
      throw new Error("Not authenticated");
    }

    const activity = this._activities.get(activityId);
    if (!activity) {
      throw new Error("Activity not found");
    }

    const data = await StravaService.getActivity(this._accessToken, activityId);

    runInAction(() => {
      this._maps.set(
        activityId,
        featureCollection([
          lineString(
            polyline.decode(data.map.polyline).map(([lon, lat]) => [lat, lon])
          ),
        ])
      );
    });
  }

  async getMapForActivity(activityId: string) {
    if (this._maps.has(activityId)) {
      return this._maps.get(activityId);
    }

    await this.getActivity(activityId);
    return this._maps.get(activityId);
  }

  async getActivities(page: number = 1, perPage: number = 10) {
    if (!this._accessToken) {
      throw new Error("Not authenticated");
    }

    const data = await StravaService.getLoggedInAthleteActivities(
      this._accessToken,
      page,
      perPage
    );

    runInAction(() => {
      for (const activity of data) {
        this._activities.set(activity.id, {
          id: activity.id,
          name: activity.name,
          distanceMetres: activity.distance,
          movingTimeSeconds: activity.moving_time,
          startDate: new Date(activity.start_date),
          averageSpeed: activity.average_speed,
          maxSpeed: activity.max_speed,
          averageHeartrate: activity.average_heartrate,
          maxHeartRate: activity.max_heartrate,
        });
      }
    });
  }
}
