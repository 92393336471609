export const PATH_COLOR_NAMES = ['ORANGE', 'ROSE', 'PINK', 'YELLOW', 'BLACK'] as const;
export type PathColorName = typeof PATH_COLOR_NAMES[number];

export type PathColor = {
    name: PathColorName;
    hex: string;
};

export const getPathColor = (name: PathColorName): PathColor => {
    switch (name) {
        case 'ORANGE':
            return { name, hex: '#fd4f01' };
        case 'ROSE':
            return { name, hex: '#c94277' };
        case 'PINK':
            return { name, hex: '#f5cce8' };
        case 'YELLOW':
            return { name, hex: '#ffc759' };
        case 'BLACK':
            return { name, hex: '#0d1b1e' };
    }
}

export const MAP_COLOR_NAMES = ['DARK', 'LIGHT'] as const;
export type MapColorName = typeof MAP_COLOR_NAMES[number];

export type MapColor = {
    name: MapColorName;
    hex: string;
};

export const getMapColor = (name: MapColorName): MapColor => {
    switch (name) {
        case 'DARK':
            return { name, hex: '#1a1a1a' };
        case 'LIGHT':
            return { name, hex: '#dbdbdc' };
    }
}

export const getMapTheme = (name: MapColorName): string => {
    switch (name) {
        case 'DARK':
            return "mapbox://styles/ldgrp/clwxkj2g701fv01rd0zig7gd9";
        case 'LIGHT':
            return 'mapbox://styles/ldgrp/clwyuvfsr01hz01rd84g6gjls';
    }
}