import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

type TextInputProps = {
    /**
     * The label of the input
     */
    label: string;
    /**
     * The unique identifier of the input
     */
    id: string;
    /**
     * The placeholder of the input
     */
    placeholder: string;
    /**
     * The value of the input
     */
    value: string;
    /**
     * A function that is called when the value changes 
     */
    onInput: (value: string) => void;
}

export const TextInput = ({ label, placeholder, id, value, onInput }: TextInputProps) => {
    const _onInput = (e : React.ChangeEvent<HTMLInputElement>) => {
        onInput(e.target.value);
    }

    return (
        <div className="grid w-full items-center gap-1.5">
            <Label htmlFor={id}>{label}</Label>
            <Input type="text" id={id} placeholder={placeholder} value={value} onInput={_onInput} />
        </div>
    );
};