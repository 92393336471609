import { Printer } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../context/useSession";
import { Button } from "../ui/button";

export const PrintButton = observer(() => {
    const { deviceStore } = useStore();
    const buttonSize = deviceStore.isMobile() ? 'sm' : 'default';
    return (
        <Button disabled size={buttonSize}>
            <Printer className="mr-2 h-4 w-4"/> Print
        </Button>
    )
});
