import { FeatureCollection } from "geojson";
import { PPActivity as PPActivity } from "../ActivityStore";
import { StravaActivity, StravaStore } from "../StravaStore";
import { FileStore, GPXActivity } from "../FileStore";

/**
 * Anything that can be a source of activity data
 */
export interface PPActivitySource {
    /**
     * Gets a list of activities
     */
    getPPActivities(): PPActivity[];

    /**
     * Gets the data for a specific activity as GeoJSON
     */
    getActivityGeoJSON(activityId: string): Promise<FeatureCollection | undefined>
}

export class StravaActivitySource implements PPActivitySource {
    constructor(private readonly stravaStore: StravaStore) { }

    getPPActivities(): PPActivity[] {
        return this.stravaStore.activities.map(this.stravaActivityToActivity);
    }

    private stravaActivityToActivity(activity: StravaActivity): PPActivity {
        return {
            id: activity.id,
            name: activity.name,
            startDate: activity.startDate,
            source: 'strava',
            activityType: 'run',
            distanceMetres: activity.distanceMetres,
            movingTimeSeconds: activity.movingTimeSeconds,
        }
    }

    async getActivityGeoJSON(activityId: string): Promise<FeatureCollection | undefined> {
        return this.stravaStore.getMapForActivity(activityId);
    }
}

export class GPXActivitySource implements PPActivitySource {
    constructor(private readonly fileStore: FileStore) { }

    getPPActivities(): PPActivity[] {
        return this.fileStore.gpxActivities.map(this.gpxActivityToActivity);
    }

    private gpxActivityToActivity(activity: GPXActivity): PPActivity {
        return {
            id: activity.id,
            name: activity.name,
            startDate: activity.startDate,
            source: 'gpx',
        }
    }

    async getActivityGeoJSON(activityId: string): Promise<FeatureCollection | undefined> {
        const activity = this.fileStore.getFile(activityId);
        if (!activity) {
            return undefined;
        }
        return activity;
    }
}