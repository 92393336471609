import { Share } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../context/useSession";
import { Button } from "../ui/button";

export const ShareButton = observer(() => {
    const { deviceStore } = useStore();
    const buttonSize = deviceStore.isMobile() ? 'sm' : 'default';
    return (
        <Button disabled size={buttonSize}>
            <Share className="mr-2 h-4 w-4" /> Share
        </Button>
    )
});
