import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import { FeatureCollection } from "geojson";
import { PPActivitySource, GPXActivitySource, StravaActivitySource } from "./activity/PPActivtySource";

export type ActivitySourceType = 'strava' | 'gpx';
export type PPActivity = {
    id: string;
    name: string;
    startDate: Date;
    source: ActivitySourceType;
    activityType?: string;
    distanceMetres?: number;
    movingTimeSeconds?: number;
}

export type ActivityWithData = PPActivity & {
    data: FeatureCollection;
}

export class ActivityStore implements Disposable {
    /**
     * A list of activity ids that are currently visible in the editor.
     */
    private _visibleActivities: Set<string> = new Set();
    private _maps = new Map<string, FeatureCollection>();

    private _activitySources: Record<ActivitySourceType, PPActivitySource>;

    constructor(private readonly rootStore: RootStore) {
        makeAutoObservable(this);

        this._activitySources = {
            strava: new StravaActivitySource(this.rootStore.stravaStore),
            gpx: new GPXActivitySource(this.rootStore.fileStore),
        }
    }

    [Symbol.dispose]() {
        this._visibleActivities.clear();
    }

    get visibleActivities(): PPActivity[] {
        return this.activities.filter((activity) => this._visibleActivities.has(activity.id));
    }

    get visibleMaps(): [string ,FeatureCollection][] {
        return this.visibleActivities.map((activity) => {
            return [activity.id.toString(), this._maps.get(activity.id)];
        }).filter(([, map]) => map !== undefined) as [string, FeatureCollection][];
    }

    get activities(): PPActivity[] {
        return Object.values(this._activitySources).flatMap((source) => source.getPPActivities());
    }

    async getActivityData(activity: PPActivity): Promise<FeatureCollection> {
        const map = await this._activitySources[activity.source].getActivityGeoJSON(activity.id);
        if (!map) {
            throw new Error(`Failed to load data for activity ${activity.id}`);
        }
        this._maps.set(activity.id, map);
        return map!;
    }

    isActivityVisible(activityId: string) {
        return this._visibleActivities.has(activityId);
    }

    setActivityVisible(activityId: string, visible: boolean) {
        if (visible) {
            this._visibleActivities.add(activityId);
        } else {
            this._visibleActivities.delete(activityId);
        }
    }
}