import { observer } from "mobx-react-lite"
import { useStore } from "../context/useSession";
import { useEffect } from "react";
import { Mapbox } from "./Mapbox";
import { TitleElement } from "./Title";
import { SubtitleElement } from "./Subtitle";

export const Editor = observer(() => {
    const { sessionStore, editorStore, deviceStore } = useStore();

    useEffect(() => {
        const items = [
            {
                x: 0,
                y: 0,
                width: editorStore.unzoomedDimensions.width,
                height: editorStore.unzoomedDimensions.height,
                element: <Mapbox/>
            },
            {
                x: 20,
                y: editorStore.unzoomedDimensions.height - 160,
                width: editorStore.unzoomedDimensions.width - 40,
                height: 72,
                element: <TitleElement/>
            },
            {
                x: 20,
                y: editorStore.unzoomedDimensions.height - 110,
                width: editorStore.unzoomedDimensions.width - 40,
                height: 72,
                element: <SubtitleElement/>
            }
        ]
        const ids = items.map(item => editorStore.addItem(item));

        return () => ids.forEach(id => editorStore.removeItem(id));
    }, [editorStore, sessionStore, sessionStore.printSize])

    return (
        <div 
            style={{
                width: editorStore.dimensions.width,
                height: editorStore.dimensions.height,
                marginTop: deviceStore.isMobile() ? "-45px" : undefined,
            }}
            className="bg-white border-2 box-content border-neutral-300 absolute shadow-lg md:shadow-xl"
        >
            <div style={{
                position: "relative",
                transformOrigin: "0 0",
                transform: `scale(${editorStore.zoom})`,
                width: editorStore.unzoomedDimensions.width,
                height: editorStore.unzoomedDimensions.height
            }}>
                {editorStore.render.map((item) => {
                    return <div key={item.id}
                        style={{
                            position: "absolute",
                            left: item.x,
                            top: item.y,
                            width: item.width,
                            height: item.height,
                        }}
                    >
                        {item.element}
                    </div>
                })}
            </div>

        </div>
    )
})
