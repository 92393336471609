/**
 * Given the size of the object and the container, calculate the zoom level
 * that should be applied to the object to fit it inside the container.
 */
export function calculateZoomToFit(
  objectWidth: number,
  objectHeight: number,
  containerWidth: number,
  containerHeight: number
) {
  const widthRatio = containerWidth / objectWidth;
  const heightRatio = containerHeight / objectHeight;

  return Math.min(widthRatio, heightRatio);
}