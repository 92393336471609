const CLIENT_ID = import.meta.env.VITE_STRAVA_CLIENT_ID;
const CLIENT_SECRET = import.meta.env.VITE_STRAVA_CLIENT_SECRET;
const REDIRECT_URI = import.meta.env.VITE_STRAVA_REDIRECT_URI;

export type StravaAthlete = {
    id: string;
    username: string;
    firstname: string;
    lastname: string;
}

export type GetTokenResponse = {
    access_token: string;
    athlete: StravaAthlete;
    expires_at: number;
    expires_in: number;
    refresh_token: string;
    token_type: string;
}

export class StravaOAuth {
    static authorize() {
        const queryParams = new URLSearchParams({
            client_id: CLIENT_ID,
            redirect_uri: REDIRECT_URI,
            response_type: 'code',
            scope: 'activity:read_all',
            approval_prompt: 'auto',
        });

        const url = `https://www.strava.com/oauth/authorize?${queryParams.toString()}`;

        // Open the Strava OAuth page in the same window
        window.location.href = url;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static async getToken(code: string, _scope: string): Promise<GetTokenResponse> {
        const body = new URLSearchParams({
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            code,
            grant_type: 'authorization_code'
        });

        const response = await fetch('https://www.strava.com/oauth/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body.toString()
        });

        if (!response.ok) {
            throw new Error('Failed to get token');
        }

        const data = await response.json();
        return data;
    }

    static saveToken(token: GetTokenResponse) {
        sessionStorage.setItem('strava_access_token', token.access_token);
        sessionStorage.setItem('strava_refresh_token', token.refresh_token);
        sessionStorage.setItem('strava_expires_at', token.expires_at.toString());
    }
}

export class StravaService {
    static async getLoggedInAthleteActivities(accessToken: string, page: number = 1, perPage: number = 10) {
        const queryParams = new URLSearchParams({
            page: page.toString(),
            per_page: perPage.toString()
        });

        const response = await fetch(`https://www.strava.com/api/v3/athlete/activities?${queryParams.toString()}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to get activities');
        }

        const data = await response.json();
        return data;
    }

    static async getActivity(accessToken: string, activityId: string) {
        const response = await fetch(`https://www.strava.com/api/v3/activities/${activityId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to get activity');
        }

        const data = await response.json();
        return data;
    }
}