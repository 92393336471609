import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/ui/drawer"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { useState } from "react"
import { Button } from "@/components/ui/button"
import { LANDSCAPE_PRINT_SIZES, PORTRAIT_PRINT_SIZES, PrintSize, getLabel } from "@/utils/PrintSize"

type PrintSizeComboboxProps = {
    isDesktop: boolean;
    printSize: PrintSize;
    onPrintSizeChanged: (printSize: PrintSize) => void
}

export function PrintSizeCombobox({ printSize, isDesktop, onPrintSizeChanged }: PrintSizeComboboxProps) {
  const [open, setOpen] = useState(false)
 
  if (isDesktop) {
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" className="w-full justify-start">
            {printSize ? <>{getLabel(printSize)}</> : <>Select Print Size</>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[250px] p-0" align="start">
          <PrintSizeList setOpen={setOpen} setSelectedPrintSize={(value) => onPrintSizeChanged(value)} />
        </PopoverContent>
      </Popover>
    )
  }
 
  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <Button variant="outline" className="w-full justify-start">
          {printSize ? <>{getLabel(printSize)}</> : <>+ Set Print Size </>}
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <div className="mt-4 border-t">
          <PrintSizeList setOpen={setOpen} setSelectedPrintSize={(value) => onPrintSizeChanged(value)} />
        </div>
      </DrawerContent>
    </Drawer>
  )
}
 
function PrintSizeList({
  setOpen,
  setSelectedPrintSize,
}: {
  setOpen: (open: boolean) => void
  setSelectedPrintSize: (status: PrintSize) => void
}) {
  return (
    <Command>
      <CommandInput placeholder="Search print size" />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup heading="Portrait">
          {PORTRAIT_PRINT_SIZES.map((status) => (
            <CommandItem
              key={status}
              value={status}
              onSelect={(value) => {
                if (value) setSelectedPrintSize(value as PrintSize)
                setOpen(false)
              }}
            >
              {getLabel(status)}
            </CommandItem>
          ))}
        </CommandGroup>
        <CommandSeparator />
        <CommandGroup heading="Landscape">
          {LANDSCAPE_PRINT_SIZES.map((status) => (
            <CommandItem
              key={status}
              value={status}
              onSelect={(value) => {
                if (value) setSelectedPrintSize(value as PrintSize)
                setOpen(false)
              }}
            >
              {getLabel(status)}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  )
}