/**
 * All the available print sizes
 */
export const PORTRAIT_PRINT_SIZES = ['A2', 'A3', 'A4', '5x7'] as const;
export const LANDSCAPE_PRINT_SIZES = ['A2 Landscape', 'A3 Landscape', 'A4 Landscape'] as const;
export const PRINT_SIZES = [...PORTRAIT_PRINT_SIZES, ...LANDSCAPE_PRINT_SIZES] as const;
export type PrintSize = typeof PRINT_SIZES[number];

export function getLabel(size: PrintSize): string {
    switch (size) {
        case 'A2':
            return 'A2 (420 x 594 mm)';
        case 'A2 Landscape':
            return 'A2 Landscape (594 x 420 mm)';
        case 'A3':
            return 'A3 (297 x 420 mm)';
        case 'A3 Landscape':
            return 'A3 Landscape (420 x 297 mm)';
        case 'A4':
            return 'A4 (210 x 297 mm)';
        case 'A4 Landscape':
            return 'A4 Landscape (297 x 210 mm)';
        case '5x7':
            return '5x7 (127 x 178 mm)';
    }
}

/**
 * @returns The dimensions of the print size in millimeters
 */
export function getPrintSizeDimensionsMillimeters(size: PrintSize) {
    switch (size) {
        case 'A2':
            return { width: 420, height: 594 };
        case 'A3':
            return { width: 297, height: 420 };
        case 'A4':
            return { width: 210, height: 297 };
        case 'A2 Landscape':
            return { width: 594, height: 420 };
        case 'A3 Landscape':
            return { width: 420, height: 297 };
        case 'A4 Landscape':
            return { width: 297, height: 210 };
        case '5x7':
            return { width: 127, height: 178 };
    }
}

/**
 * @returns The dimensions of the print in pixels
 */
export function getPrintSizeDimensionsPixels(size: PrintSize, dpi: number) {
    const dimensions = getPrintSizeDimensionsMillimeters(size);
    return {
        width: dimensions.width * dpi / 25.4,
        height: dimensions.height * dpi / 25.4,
    };
}