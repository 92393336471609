import { observer } from "mobx-react-lite";
import { useRef, useEffect, useLayoutEffect } from "react";
import { Editor } from "./Editor";
import { useStore } from "../context/useSession";
import { CenterMapButton } from "./CenterMapButton";

export const EditorContainer = observer(() => {
    const { sessionStore } = useStore();
    const { ref } = useZoom();

    /** Assign a ref to this div in session */
    useEffect(() => {
        sessionStore.editorContainer = ref.current!;
        sessionStore.zoomToFit();
    }, [ref, sessionStore])

    useSize(() => {
        if (!sessionStore.isFitToScreen) {
            return;
        }
        sessionStore.zoomToFit();
    });

    return (
        <div ref={ref} className="h-full w-full overflow-hidden relative justify-center items-center flex flex-col gap-10 -m-t-5">
            <Editor/>
            <CenterMapButton/>
        </div>
    )
})

/** Detect when the window size changes */
function useSize(handleResize: () => void) {
    useLayoutEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [handleResize])
}

function useZoom() {
    const { sessionStore } = useStore();
    const ref = useRef<HTMLDivElement>(null);
    let initialPinchDistance: number | null = null;
    let initialZoom: number | null = null;

    const handleOnWheel = (e: WheelEvent) => {
        if (!e.ctrlKey){
            return;
        }
        if (e.deltaY > 0) {
            sessionStore.zoom -= 0.1;
        } else {
            sessionStore.zoom += 0.1;
        }
        e.preventDefault();
    }

    const handleTouchStart = (e: TouchEvent) => {
        if (e.touches.length === 2) {
            initialPinchDistance = getPinchDistance(e.touches[0], e.touches[1]);
            initialZoom = sessionStore.zoom;
        }
    }

    const handleTouchMove = (e: TouchEvent) => {
        if (e.touches.length === 2 && initialPinchDistance !== null && initialZoom !== null) {
            const currentPinchDistance = getPinchDistance(e.touches[0], e.touches[1]);
            const pinchRatio = currentPinchDistance / initialPinchDistance;
            sessionStore.zoom = initialZoom * pinchRatio;
            e.preventDefault();
        }
    }

    const handleTouchEnd = () => {
        initialPinchDistance = null;
        initialZoom = null;
    }

    const getPinchDistance = (touch1: Touch, touch2: Touch) => {
        const dx = touch1.clientX - touch2.clientX;
        const dy = touch1.clientY - touch2.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const el = ref.current;
        el.addEventListener("wheel", handleOnWheel);
        el.addEventListener("touchstart", handleTouchStart);
        el.addEventListener("touchmove", handleTouchMove, { passive: false });
        el.addEventListener("touchend", handleTouchEnd);
        return () => {
            el.removeEventListener("wheel", handleOnWheel);
            el.removeEventListener("touchstart", handleTouchStart);
            el.removeEventListener("touchmove", handleTouchMove);
            el.removeEventListener("touchend", handleTouchEnd);
        }
    })
    return { ref }
}