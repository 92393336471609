import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { ActivityPanel } from "./ActivityPanel";
import { DesignPanel } from "./DesignPanel";
import { PrintSizePanel } from "./PrintSizePanel";
import { useState } from "react";
import { Drawer, DrawerContent, DrawerTrigger } from "../ui/drawer";
import { observer } from "mobx-react-lite";
import { useStore } from "../context/useSession";
import { PoweredByStravaLogo } from "./PoweredByStrava";

export const Panel = observer(() => {
    const { deviceStore } = useStore();

    if (deviceStore.deviceType === 'mobile') {
        return <MobilePanel />
    }

    return (
        <div className="flex flex-col h-full justify-between">
            <div className="bg-white m-3 p-3 pb-4 flex flex-col justify-between shadow-xl rounded-xl gap-2">
                <Tabs defaultValue="activities" className="min-w-[350px] w-[350px] flex flex-col">
                    <TabsList className="grid w-full grid-cols-3">
                        <TabsTrigger value="activities">Activities</TabsTrigger>
                        <TabsTrigger value="design">Design</TabsTrigger>
                        <TabsTrigger value="print_size">Print Size</TabsTrigger>
                    </TabsList>
                    <TabsContent value="activities">
                        <ActivityPanel />
                    </TabsContent>
                    <TabsContent value="design">
                        <DesignPanel />
                    </TabsContent>
                    <TabsContent value="print_size">
                        <PrintSizePanel />
                    </TabsContent>
                </Tabs>
            </div>
                <div className="mx-3 my-3">
                    <PoweredByStravaLogo />
                </div>
        </div>
    )
})

function MobilePanel() {
    const [open, setOpen] = useState(false);

    return (
        <div className="w-full flex justify-center">
            <Drawer open={open} onOpenChange={setOpen} setBackgroundColorOnScale={false}>
                <Tabs defaultValue="activities" className="min-w-[250px] w-[250px] flex flex-col justify-center align-center gap-2">
                    <TabsContent value="activities">
                        <DrawerContent>
                            <div className="p-4">
                                <ActivityPanel />
                            </div>
                        </DrawerContent>
                    </TabsContent>
                    <TabsContent value="design">
                        <DrawerContent>
                            <div className="p-4">
                                <DesignPanel />
                            </div>
                        </DrawerContent>
                    </TabsContent>
                    <TabsContent value="print_size">
                        <DrawerContent>
                            <div className="p-4">
                                <PrintSizePanel />
                            </div>
                        </DrawerContent>
                    </TabsContent>
                    <DrawerTrigger asChild>
                        <TabsList className="grid w-full grid-cols-3 h-full">
                            <TabsTrigger className="h-10" value="activities">Activities</TabsTrigger>
                            <TabsTrigger className="h-10" value="design">Design</TabsTrigger>
                            <TabsTrigger className="h-10" value="print_size">Print Size</TabsTrigger>
                        </TabsList>
                    </DrawerTrigger>
                </Tabs>
            </Drawer>
        </div>
    )
}