import { observer } from 'mobx-react-lite';
import './App.css'
import { EditorContainer } from './components/editor/EditorContainer';
import { Navbar } from './components/navbar/Navbar'
import { Panel } from './components/panels/Panel'
import { Toaster } from './components/ui/sonner';
const App = observer(() => {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <div className="flex h-[calc(100%-58px)] w-full md:flex-row flex-col-reverse bg-slate-100 overflow-hidden">
        <aside>
          <Panel />
        </aside>
        <main className="h-full w-full">
          <EditorContainer />
        </main>
      </div>
      <Toaster />
    </>
  )
})

export default App
