import { DeviceType } from "@/utils/Device";
import { makeAutoObservable } from "mobx";

/**
 * Stores the device information of the user
 */
export class DeviceStore implements Disposable {
    private _deviceType: DeviceType = 'desktop';

    constructor() {
        makeAutoObservable(this)

        // Set the device type based on the window width
        this.deviceType = window.innerWidth <= 768 ? "mobile" : "desktop";
        window.addEventListener("resize", this.handleResize);
    }

    [Symbol.dispose]() {
        window.removeEventListener("resize", this.handleResize);
    }

    get deviceType() {
        return this._deviceType;
    }

    set deviceType(type: DeviceType) {
        this._deviceType = type;
    }

    isMobile() {
        return this.deviceType === "mobile";
    }

    isDesktop() {
        return this.deviceType === "desktop";
    }

    handleResize = () => {
        this.deviceType = window.innerWidth <= 768 ? "mobile" : "desktop";
    }
}